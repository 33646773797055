import { useNavigate, useParams } from 'react-router-dom'
import { useCallback } from 'react'

import { Form } from '../components'

import { FormData } from '../components/Form/types'

import { useToast } from 'shared/hooks'

import { handleRenderState } from 'utilities/handleRenderState'

import { Breadcrumbs, ContainerScreen, Loader } from 'components'
import { useCreateContact } from 'services/contact'

import styles from './styles.module.scss'

import { converter } from 'domains/customer/screens/Contact/utilities'
import { useCustomerContext } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'
import { buildPath, paths } from 'routes'

const Create = () => {
  const { addToast } = useToast()
  const { accountId = '' } = useParams()
  const navigate = useNavigate()

  const { customer } = useCustomerContext()

  const { mutate, isPending } = useCreateContact()

  const onSubmit = useCallback(
    (data: FormData) => {
      const payload = converter(data)

      mutate(payload, {
        onSuccess: () => {
          addToast({ message: 'Contato cadastrado com sucesso.' })
          navigate(-1)
        },
        onError: () => {
          const message = 'Erro ao cadastrar contato. Tente novamente.'
          addToast({ message, type: 'alert' })
        },
      })
    },
    [addToast, navigate],
  )

  return (
    <>
      {
        {
          view: (
            <>
              <Loader isVisible={isPending} />
              <div className={styles.container}>
                <ContainerScreen
                  clickable
                  renderBreadcrumbs={
                    <Breadcrumbs
                      items={[
                        {
                          title: 'Clientes',
                          href: buildPath({
                            path: paths.account.details,
                            params: { accountId },
                          }),
                        },
                        {
                          title: customer?.name || '',
                          href: buildPath({
                            path: paths.account.details + '?tab=contact&page=1',
                            params: { accountId },
                          }),
                        },
                        {
                          title: 'Contatos',
                          href: buildPath({
                            path: paths.account.details + '?tab=contact&page=1',
                            params: { accountId },
                          }),
                        },
                        { title: 'NOVO CONTATO', href: '#' },
                      ]}
                    />
                  }
                >
                  <Form onSubmit={onSubmit} />
                </ContainerScreen>
              </div>
            </>
          ),
          loading: <Loader isVisible />,
          error: <></>,
          empty: <></>,
        }[
          handleRenderState({
            data: true,
            isError: false,
            isLoading: false,
            isEmpty: false,
          })
        ]
      }
    </>
  )
}

export default Create
